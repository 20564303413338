//import 'jquery/dist/jquery.min.js';
import Main from './main';
//import Instafeed from 'instafeed.js';
import 'bootstrap-sass/assets/javascripts/bootstrap.min.js';

//import jquery from 'jquery';
//window.jQuery = jquery;

const appName = 'Silverstripe Base';
const main = new Main(appName);

/*
var feed = new Instafeed({
    get: 'user',
    userId: 'self',
    accessToken: '7270391875.1677ed0.110b87a93f554e9a942d0e6884976a0d',
    template: '<a class="ig-image" href="{{link}}" target="_blank"><div style="background-image: url({{image}})"></div></a>',
    resolution: 'low_resolution',
    limit: '8',
    sortBy: 'most-recent'
    
});
*/

window.addEventListener('load', () => main.init());
